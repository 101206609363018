export const msalConfig = {
  auth: {
    clientId: "69a1beb4-4781-4d24-a941-d9ec39330c5f",
    authority:
      "https://enpalcraftos.b2clogin.com/enpalcraftos.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_CRAFT",
    knownAuthorities: ["enpalcraftos.b2clogin.com"],
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
    response_type: "token code",
    scopes: [
      "openid",
      "profile",
      "https://enpalcraftos.onmicrosoft.com/69a1beb4-4781-4d24-a941-d9ec39330c5f/Home.Read",
    ],
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const scopes = [
  "openid",
  "profile",
  "https://enpalcraftos.onmicrosoft.com/69a1beb4-4781-4d24-a941-d9ec39330c5f/Home.Read",
];

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com",
};
