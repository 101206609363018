import "./App.scss";
import {
  useMsal,
  useMsalAuthentication,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { AppLayout, AppView, User, EnviromentType } from "craftos-ui";
import { Layout, Skeleton } from "antd";

function App() {
  useMsalAuthentication(InteractionType.Redirect);

  const { instance } = useMsal();
  const { Content } = Layout;
  const userAcc = instance.getAllAccounts()[0];
  instance.setActiveAccount(userAcc);

  let usrObj = new User({});
  if (userAcc) {
    usrObj = new User(userAcc);
  }

  const appEnviroment = process.env.REACT_APP_ENVIRONMENT as EnviromentType;

  const onLogout = () =>
    instance.logoutRedirect({
      account: instance.getActiveAccount(),
    });

  return (
    <AppLayout
      appName="CraftOS"
      onLogout={onLogout}
      user={usrObj}
      appEnviroment={appEnviroment}
      hideApps
    >
      <Content className="fullHeight padding-1x">
        <AuthenticatedTemplate>
          <AppView user={usrObj} env={appEnviroment} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Skeleton active />
        </UnauthenticatedTemplate>
      </Content>
    </AppLayout>
  );
}

export default App;
